.container {
  contain: strict;
  height: rem(70);
  display: grid;
  grid-template-columns: rem(70) 1fr;
  column-gap: rem(15);
}

.desc {
  display: grid;
  row-gap: rem(8);
  column-gap: rem(10);
  grid-template-columns: auto 1fr;
}

.imageContainer {
  width: rem(70);
  height: rem(70);
  grid-row: span 2;
}

.title {
  font-size: rem(18);
  line-height: rem(24);
  font-weight: bold;
  grid-column: span 2;

  @mixin lineClamp 2;
}

.score {
  color: clr(p-70);
}

.seeAlso {
  color: clr(g-20);
  font-size: rem(12);
  line-height: 1;
}
