.container {
  @media bp(lg) {
    grid-column: span 2;
  }
}

.grid {
  display: grid;
  row-gap: rem(15);

  @media bp(sm) {
    column-gap: rem(15);
    grid-template-columns: repeq(2);
  }

  @media bp(lg) {
    gap: rem(30);
    grid-template-columns: repeq(4);
  }
}
