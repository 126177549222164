.container {
  background: clr(lightable-b);
  color: clr(cw);
  height: rem(105);
  content-visibility: auto;
  contain-intrinsic-size: 0 rem(105);
  padding: rem(30);
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: space-between;
  box-shadow: inset 0 rem(20) rem(40) rem(-20) rgba(var(--color), 0.4);

  &::after {
    content: '';
    height: rem(3);
    background-color: rgb(var(--color));
  }

  &:hover {
    .arrowIcon {
      opacity: 1;
    }
  }

  @media bp(sm) {
    height: rem(150);
    contain-intrinsic-size: 0 rem(150);
    box-shadow: 0 rem(20) rem(40) rem(-20) rgba(var(--color), 0.4);
  }
}

.title {
  font-size: rem(18);
  line-height: rem(20);
  font-weight: bold;
  display: flex;
  align-items: center;
}

.titleText {
  flex: 1;

  @mixin textEllipsis;
}

.arrowIcon {
  width: rem(12);
  height: rem(12);
  opacity: 0.2;
  transition: trn(opacity);
}
