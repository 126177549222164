.container {
  width: calc(100vw - rem(30));

  @media bp(lg) {
    width: auto;
    grid-column: span 2;
  }
}

.postHero {
  display: flex;
  flex-direction: column;
  gap: rem(15);

  @media bp(lg) {
    display: grid;
    gap: rem(15) rem(20);
    grid-template-columns: rem(280) auto rem(280);
    grid-template-areas:
      'tl m tr'
      'bl m br';
    align-items: start;
  }

  .m {
    grid-area: m;
  }

  .tl {
    grid-area: tl;
  }

  .bl {
    grid-area: bl;
  }

  .tr {
    grid-area: tr;
  }

  .br {
    grid-area: br;
  }
}

.leaderboard {
  display: flex;
  overflow: hidden;
  align-items: center;
  justify-content: center;

  @media bp(lg) {
    grid-column: span 2;
  }
}

.playwire {
  min-height: auto;

  @media bp(lg) {
    min-height: rem(250);
  }
}
