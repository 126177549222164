.container {
  display: grid;
  row-gap: rem(40);

  @media bp(lg) {
    grid-column: span 2;
    grid-template-columns: minmax(0, 1fr) rem(300);
    column-gap: rem(30);
    align-items: start;

    .primary {
      order: 10;
    }

    .secondary {
      order: 20;
    }
  }
}

.title {
  font-size: rem(20);
  line-height: rem(24);
  font-weight: bold;

  @media bp(sm) {
    font-size: rem(28);
    line-height: rem(34);
  }
}

.button {
  height: rem(50);
}

.primary,
.secondary {
  display: grid;
  row-gap: rem(15);

  @media bp(md) {
    row-gap: rem(30);
  }
}

.primaryList {
  display: grid;
}
