.container {
  contain: strict;
  position: relative;
  padding-top: 46.55%;
  aspect-ratio: 290 / 135;
  color: clr(cw);

  @media bp(lg) {
    transition: trn(transform);
    padding-top: 100%;
    aspect-ratio: 1 / 1;

    &:hover {
      transform: scale(1.1);
    }
  }
}

.imageContainer {
  &::after {
    content: '';
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    background-color: clr(cb/70);
  }
}

.title {
  position: absolute;
  bottom: rem(30);
  left: rem(30);
  max-width: calc(100% - rem(60));
  font-size: rem(14);
  line-height: 1;
  letter-spacing: rem(4);
  text-transform: uppercase;
  font-weight: bold;

  @mixin textEllipsis;
}

.icon {
  width: rem(28);
  height: rem(28);
  position: absolute;
  left: rem(30);
  top: rem(30);
}
