.container {
  contain: content;
  display: grid;

  @media bp(sm) {
    row-gap: rem(15);
  }

  @media bp(md) {
    row-gap: rem(30);
  }

  @media bp(lg) {
    grid-column: span 2;
  }
}

.title {
  margin-bottom: 0;
}

.grid {
  display: grid;

  @media bp(sm) {
    grid-template-columns: repeq(2);
    gap: rem(15);
  }

  @media bp(lg) {
    grid-template-columns: repeq(4);
    gap: rem(30);
  }
}
