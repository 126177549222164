.container {
  @media bp(lg) {
    grid-column: span 2;
    display: grid;
    grid-template-columns: repeq(2);
    column-gap: rem(30);
  }
}

.subtitle,
.title {
  margin-bottom: rem(20);
  color: clr(p-70);
}

.list {
  @media bp(lg) {
    grid-column: span 2;
  }
}

.subtitle {
  display: none;

  @media bp(lg) {
    display: block;
  }
}
